import {
  doc,
  updateDoc,
  getFirestore,
  Timestamp,
  collection,
  query,
  where,
  getDocs,
  getDoc,
} from "firebase/firestore";
import dayjs from "dayjs";
import { getStorage, uploadBytes, getDownloadURL, ref } from "firebase/storage";
import _ from "lodash";

async function uploadUserDocs(
  data,
  setUserData,
  docs,
  setSpinner,
  setInput,
  settSuccess,
  setError
) {
  console.log(docs);

  setSpinner(true);

  uploadMultipleFiles(docs).then(async (downloadURLs) => {
    let urls = [];
    const db = getFirestore();

    const uploadArray = downloadURLs.map((link, index) => {
      return {
        name: docs[index].name,
        link,
        date: Timestamp.fromDate(new Date(dayjs().valueOf())),
      };
    });

    //console.log(uploadArray)

    const userRef = doc(db, "users", data.id);

    if (data.hasOwnProperty("addedDocs")) {
      urls = [...data.addedDocs, ...uploadArray];
    } else {
      urls = uploadArray;
    }

    try {
      await updateDoc(userRef, {
        addedDocs: urls,
      });
    } catch (error) {
      console.log(error);
      setSpinner(false);
      setError("Error uploading file");
      return;
    }

    // console.log("Upload success")

    const editedObj = data;

    editedObj.addedDocs = urls;
    setUserData(editedObj);

    setInput([]);
    setSpinner(false);
    settSuccess("Files uploaded Successfully");
  });
}

// Helper function to upload a document to Firebase Storage
async function uploadFileAndGetURL(file, fileName) {
  const storage = getStorage();

  // Create a unique file name by appending the current timestamp
  const uniqueFileName = `${Date.now()}_${fileName}`;
  const storageRef = ref(storage, uniqueFileName);

  try {
    // Upload the file
    const snapshot = await uploadBytes(storageRef, file);
    // console.log("File uploaded successfully");

    // Get the download URL
    const downloadURL = await getDownloadURL(snapshot.ref);
    // console.log("Download URL:", downloadURL);
    return downloadURL;
  } catch (error) {
    console.error("Error uploading file:", error);
    // throw error;
  }
}

async function uploadMultipleFiles(files) {
  // console.log(files);
  const downloadURLs = [];

  for (let i = 0; i < files.length; i++) {
    const file = files[i];

    const fileName = file.name; // Use the original file name

    try {
      // Upload the file and get the download URL
      const downloadURL = await uploadFileAndGetURL(file, fileName);
      // Push the download URL into the array
      downloadURLs.push(downloadURL);
    } catch (error) {
      console.error(`Error uploading file ${fileName}:`, error);
    }
  }

  return downloadURLs;
}
async function updatePassword(
  user,
  newPassword,
  setSuccess,
  setError,
  setUser,
  setShowSpinner,
  navigate
) {
  const db = getFirestore();
  try {
    const userRef = doc(db, "users", user.id);
    await updateDoc(userRef, {
      password: newPassword,
    });

    setUser({ ...user, password: newPassword });
    setShowSpinner(false);
    setSuccess("Password updated successfully");
    // navigate('/')
    // console.log('Password updated successfully.');
  } catch (error) {
    setShowSpinner(false);
    setError("Error updating password");

    // console.error('Error updating password:', error);
    // throw error;
  }
}

async function addBrokerInfo(
  user,
  agent,
  setUser,
  setShowSpinner,
  setSuccess,
  setError
) {
  const db = getFirestore();
  try {
    const userRef = doc(db, "users", user.id);
    await updateDoc(userRef, {
      agent,
    });

    setUser({ ...user, agent });
    setShowSpinner(false);
    setSuccess("Data uploaded successfully");
  } catch (error) {
    setShowSpinner(false);
    setError("Error uploading data");
  }
}

function getFileType(file) {
  const fileExtension = file.name.split(".").pop().toLowerCase();
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];

  if (imageExtensions.includes(fileExtension)) {
    return "img";
  } else if (fileExtension === "pdf") {
    return "pdf";
  } else {
    return "other";
  }
}

function getLoggedIn() {
  return localStorage.getItem("loggedIn");
}

function setLoggedIn(value) {
  localStorage.setItem("loggedIn", value);
}

async function reloadData(userData, setUserData, setSpinner, navigate) {
  //console.log(userData)
  setSpinner(true);
  const db = getFirestore();
  const usersRef = collection(db, "users");

  const result = [];

  const isLoggedIn = await getLoggedIn();
  //console.log(isLoggedIn)
  if (isLoggedIn === "false") {
    // console.log("here");
    setUserData({});
    setSpinner(false);
    navigate("/");
    return;
  } else {
    const q1 = query(
      usersRef,
      where("email", "==", userData.email),
      where("password", "==", userData.password)
    );
    const querySnapshot = await getDocs(q1);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // console.log(doc.id, " => ", doc.data());
      result.push({ id: doc.id, ...doc.data() });
    });

    if (_.isEmpty(result)) {
      setUserData({});
      setLoggedIn('false')
      setSpinner(false);
      navigate("/");
    } else {
      let userData = result[0];
      let companyData = {};

      if (userData.companyID !== "") {
        const docRef = doc(db, "companies", result[0].companyID);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          companyData = docSnap.data();
          // console.log("Document data:", docSnap.data());
        } else {
          // docSnap.data() will be undefined in this case
          // console.log("No such document!");
        }
      }

      //console.log(companyData)
      //console.log(userData)

      if (_.isEmpty(companyData)) {
        setUserData(userData);
      } else {
        setUserData({ ...userData, ...companyData });
      }

      setSpinner(false);
    }
  }
}

export { uploadUserDocs, updatePassword, addBrokerInfo, getFileType, reloadData, setLoggedIn, getLoggedIn };
