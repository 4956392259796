import React, { useContext, useState, useEffect } from 'react'
import { AppDataContext } from '../../Contexts/AppDataContext';
import { useLocation, useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from './Header';

import { Footer, Spinner } from '../shared';
import Logo from '../../img/Logo-nobg.png'
import HeadImg from '../../img/application.png'
import { Input, Select, DateInput, CheckBoxGroup, InputArea } from '../shared/Inputs';
import { MdRealEstateAgent } from "react-icons/md";
import { BsTelephoneFill } from "react-icons/bs";
import { IoIosMail } from "react-icons/io";
import { BiSolidBusiness } from "react-icons/bi";
import { GiHouse } from "react-icons/gi";

import { HiClipboardDocumentList } from "react-icons/hi2";
import { toast, ToastContainer } from 'react-toastify';
import _ from "lodash";
import { addBrokerInfo, reloadData } from './dbFunctions';


const inlineStyle = {
    display: 'none',
    width: '0'
}

function Forms() {

    const { userData, errorInput, setErrorInput, setUserData } = useContext(AppDataContext)
    const location = useLocation()
    const navigate = useNavigate()

    const [agent, setAgent] = useState({
        agentName: "",
        agentLicence: "",
        agentBusiness: "",
        agentEmail: "",
        agentAddress: "",
        agentPhone: ""
    });

    const handleAgentChange = (e) => {
        const { name, value } = e.target;
        setAgent((prevAgent) => ({
            ...prevAgent,
            [name]: value
        }));
    };

    const [showSpinner, setShowSpinner] = useState(false)

    useEffect(() => {

        const handleRefresh = () => {
            const entries = performance.getEntriesByType('navigation');
            const navigationEntry = entries.length > 0 ? entries[0] : null;

            if (navigationEntry && navigationEntry.type === 'reload' && _.isEmpty(userData)) {
                console.log('Page refreshed');
                // Perform actions like re-fetching data, resetting state, etc.
                reloadData(location.state, setUserData, setShowSpinner, navigate)

            }
        };

        handleRefresh();

    }, [userData])

    function getErrorInput(pageInputs) {
        for (const key in pageInputs) {
            if (pageInputs.hasOwnProperty(key) && !pageInputs[key]) {
                //console.log(key)
                setErrorInput(key);
                return;
            }
        }
    }

    const setError = (message) => toast.error(message, { position: 'top-center' })
    const setSuccess = (message) => toast.success(message, { position: 'top-center' })

    function hasEmptyField(obj) {
        return _.some(obj, value => {
            // Exclude Date objects from being considered "empty"
            if (_.isDate(value)) {
                return false; // Date objects are never considered empty
            }

            // Check for empty values using lodash's isEmpty, excluding numbers, booleans, and dates
            return _.isEmpty(value) && !_.isNumber(value) && !_.isBoolean(value);
        });
    }

    function handleSubmit() {
        if (hasEmptyField(agent)) {
            getErrorInput({ ...agent })
            setError("Please complete forms")
            return
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailRegex.test(agent.agentEmail)) {

            setShowSpinner(true)
            addBrokerInfo(userData, agent, setUserData, setShowSpinner, setSuccess, setError)

        } else {
            setError('Check the email address format')
            setErrorInput('agentEmail')
        }
    }

    return (
        <div className="home">
            <Sidebar data={location.state} />
            <Header data={location.state} />
            <div className="home__main">
                <div className="home__main__header">
                    <p>Your Broker Info Area</p>
                    <h3>Forms</h3>

                </div>

                <div className="home__main__content">

                    {
                        showSpinner &&
                        <Spinner showSpinner={showSpinner} />
                    }

                    <div className='userforms' style={showSpinner ? inlineStyle : {}}>
                        <div className="userforms__container">

                            <div className="userforms__header">
                                <div className="userforms__header__text">
                                    <h4>Welcome to your Forms Section</h4>
                                    <p>Real Estate Agency Council Hub Ghana</p>
                                </div>

                                <div className="userforms__header__img">
                                    <img src={HeadImg} alt="Page Icon" />
                                </div>

                            </div>

                            <div className="userforms__content">

                                <AgentForms
                                    agent={agent}
                                    handleAgentChange={handleAgentChange}
                                    handleSubmit={() => handleSubmit()}
                                />

                            </div>
                        </div>
                    </div>


                </div>
                <Footer isHome />
                <ToastContainer />
            </div>
        </div>
    )
}

export default Forms

const AgentForms = (props) => {



    return (
        <div className="userforms__content__container">
            <div className="userforms__content__forms">
                <Input
                    id="agentName"
                    label="Name of Broker you intend to be affiliated with"
                    value={props.agent.agentName}
                    setValue={props.handleAgentChange}
                    placeholder="Affiliate Name"
                    type="obj"
                >
                    <MdRealEstateAgent />
                </Input>

                <Input
                    id="agentLicence"
                    label="Licence number of affiliated broker"
                    value={props.agent.agentLicence}
                    setValue={props.handleAgentChange}
                    placeholder="Affiliate Broker's Licence"
                    type="obj"
                >
                    <HiClipboardDocumentList />
                </Input>

                <Input
                    id="agentBusiness"
                    label=" Business/Company Name of Broker"
                    value={props.agent.agentBusiness}
                    setValue={props.handleAgentChange}
                    placeholder="Affiliate Business Name"
                    type="obj"
                >
                    <BiSolidBusiness />
                </Input>

                <Input
                    id="agentEmail"
                    label="Email address of the Broker"
                    value={props.agent.agentEmail}
                    setValue={props.handleAgentChange}
                    placeholder="Affiliate Email"
                    type="obj"
                >
                    <IoIosMail />
                </Input>

                <Input
                    id="agentAddress"
                    label="Business address of the Broker (with Ghana post GPS)"
                    value={props.agent.agentAddress}
                    setValue={props.handleAgentChange}
                    placeholder="Affiliate Business Address"
                    type="obj"
                >
                    <GiHouse />
                </Input>

                <Input
                    id="agentPhone"
                    label="Phone Number of the Broker"
                    value={props.agent.agentPhone}
                    setValue={props.handleAgentChange}
                    placeholder="Affiliate Phone Number"
                    type="obj"
                >
                    <BsTelephoneFill />
                </Input>
            </div>

            <div className="userforms__content__btnsection">
                <button
                    className="btn adddocs__btn"
                    onClick={() => props.handleSubmit()}
                >
                    Submit
                </button>
            </div>

        </div>
    )
}