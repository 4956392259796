import React, { useState, useContext, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Sidebar from './Sidebar';
import HeadImg from '../../img/reset-password.png'
import Logo from '../../img/Logo-nobg.png'
import Header from './Header';
import { FaLock, FaUnlock } from "react-icons/fa6";
import { IoLockClosed } from 'react-icons/io5'
import { AppDataContext } from '../../Contexts/AppDataContext';
import { updatePassword } from './dbFunctions';
import { Spinner, Footer } from '../shared';
import { reloadData } from './dbFunctions';
import _ from 'lodash'

const inlineStyle = {
    display: 'none',
    width: '0'
}

function ResetPword() {

    const navigate = useNavigate()
    const location = useLocation()
    const { userData, setUserData } = useContext(AppDataContext)
    const [oldPword, setOldPword] = useState("")
    const [newPword, setNewPword] = useState("")
    const [retypePword, setRetypePword] = useState("")

    const [showSpinner, setShowSpinner] = useState(false)

    const notify = (message) => toast.success(message, { position: 'top-center' });

    const setError = (message) => toast.error(message, { position: 'top-center' })

    useEffect(() => {

        const handleRefresh = () => {
            const entries = performance.getEntriesByType('navigation');
            const navigationEntry = entries.length > 0 ? entries[0] : null;

            if (navigationEntry && navigationEntry.type === 'reload' && _.isEmpty(userData)) {
                console.log('Page refreshed');
                // Perform actions like re-fetching data, resetting state, etc.
                reloadData(location.state, setUserData, setShowSpinner, navigate)

            }
        };

        handleRefresh();

    }, [userData])


    function handleUpdatePassword() {

        setShowSpinner(true)

        if (oldPword === userData.password) {
            if (newPword === retypePword) {

                if (newPword === userData.password) {
                    setShowSpinner(false);
                    setError("New password cannot be the same as the old password.");

                } else {
                    if (newPword.length > 8) {
                        updatePassword(userData, newPword, notify, setError, setUserData, setShowSpinner, navigate)
                    } else {
                        setShowSpinner(false)
                        setError("Password too weak")
                    }
                }

            } else {
                setShowSpinner(false)
                setError("Password don't match")
            }
        } else {
            setShowSpinner(false)
            setError("Old Password wrong")
        }

    }

    return (
        <div className="home">
            <Sidebar data={location.state} />
            <Header data={location.state} />
            <div className="home__main">
                <div className="home__main__header">
                    <p>Reset your Logins Section</p>
                    <h3>Reset Password</h3>

                </div>

                <div className="home__main__content">
                    <div className='resetpword'>
                        <div className="resetpword__container">

                            <div className="resetpword__header">
                                <div className="resetpword__header__text">
                                    <h4>Reset Account Password Section</h4>
                                    <p>Real Estate Agency Council Hub Ghana</p>
                                </div>

                                <div className="resetpword__header__img">
                                    <img src={HeadImg} alt="Page Icon" />
                                </div>

                            </div>

                            <div className="resetpword__content">

                                {
                                    showSpinner &&
                                    <Spinner showSpinner={showSpinner} />
                                }

                                <div className="resetpword__main" style={showSpinner ? inlineStyle : {}}>

                                    <div className="resetpword__main__container">

                                        <div className="resetpword__input__wrap">
                                            <label htmlFor="oldpword">Old Password</label>
                                            <div className="resetpword__input__box">
                                                <div className="resetpword__input__iconbox">
                                                    <FaUnlock className="resetpword__input__icon" />
                                                </div>

                                                <input
                                                    type="password"
                                                    className="resetpword__input"
                                                    name="oldpword"
                                                    value={oldPword}
                                                    onChange={(e) => setOldPword(e.target.value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="resetpword__input__wrap">
                                            <label htmlFor="newpword">New Password</label>
                                            <div className="resetpword__input__box">
                                                <div className="resetpword__input__iconbox">
                                                    <FaLock className="resetpword__input__icon" />
                                                </div>

                                                <input
                                                    type="password"
                                                    className="resetpword__input"
                                                    name="newpword"
                                                    id="newpword"
                                                    value={newPword}
                                                    onChange={(e) => setNewPword(e.target.value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="resetpword__input__wrap">
                                            <label htmlFor="retypepword">Retype New Password</label>
                                            <div className="resetpword__input__box">
                                                <div className="resetpword__input__iconbox">
                                                    <IoLockClosed className="resetpword__input__icon" />
                                                </div>

                                                <input
                                                    type="password"
                                                    className="resetpword__input"
                                                    name="retypepword"
                                                    id="retypepword"
                                                    value={retypePword}
                                                    onChange={(e) => setRetypePword(e.target.value)}
                                                />
                                            </div>
                                        </div>

                                        <div
                                            className="resetpword__main__btnsection"
                                            onClick={() => handleUpdatePassword()}
                                        >
                                            <button className="btn resetpword__main__btn">Change Password</button>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>
            <ToastContainer />
        </div>
    )
}

export default ResetPword