import React, { useEffect, useState, useContext } from 'react'
import { AppDataContext } from '../../Contexts/AppDataContext';
import { GiClover, GiChessQueen } from "react-icons/gi";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai'
import { useNavigate } from "react-router-dom";
import { MdEmail } from "react-icons/md";
import { IoIosPerson } from "react-icons/io";
import { Footer } from '../shared';
import { toast, ToastContainer } from 'react-toastify';
import { RiLockPasswordFill } from "react-icons/ri";
import PaymentPage from '../Signup/PaymentPage';
import Logo from '../../img/Logo-nobg.png'
import { Spinner } from '../shared';
import { loginUser } from './dbFunctions';

const inlineStyle = {
    display: 'none',
    width: '0',
}

function Login() {

    const { userData, setUserData } = useContext(AppDataContext)
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false)
    const [email, setEmail] = useState('')
    const [pword, setPword] = useState('')
    const [showSpinner, setShowSpinner] = useState(false)

    const setError = (message) => toast.error(message, { position: 'top-center' })

    async function handleLogin() {
        if (email === "" || pword === "") {
            setError("Please Enter email and password")
            return
        }

        setShowSpinner(true)

        loginUser(email, pword, setUserData, setShowSpinner, navigate, setError)
    }

    return (
        <div className="create__wrapper">
            <div className='create__container'>
                <PaymentPage
                    showModal={showModal}
                    hideModal={() => setShowModal(false)}
                />


                <div className='create' id="create">
                    <div className="create__left">

                        <h2 className="create__left__header">Welcome Back To Real Estate Agency Council Hub (REACH)</h2>

                        <img src={Logo} alt="Logo of company" />

                        <p className='create__left__text'>
                            Welcome back to the management portal! Your return signifies another step towards our collective growth and success.
                            Let's harness our resources and expertise to guide our agents towards unparalleled achievement in the real estate realm.
                        </p>

                        <button
                            className='btn create__left__btn'
                            onClick={() => navigate('/signup')}
                        >
                            Sign up
                        </button>
                    </div>

                    <div
                        className="create__right--login"
                    >
                        <div className="create__right__phonelogo">
                            <img src={Logo} alt="Logo of company" />
                        </div>

                        <h2>Login </h2>

                        <div className="create__right__banner">
                            <div className='create__right__banner__icon'>
                                <GiChessQueen />
                            </div>
                            <div className="create__right__banner__text">
                                Enter Details
                            </div>

                        </div>

                        {
                            showSpinner &&
                            <div className="create__right__form">
                                <Spinner showSpinner={showSpinner} />
                            </div>
                        }

                        <div className="create__right__form" style={showSpinner ? inlineStyle : {}}>

                            <Form
                                email={email}
                                setEmail={setEmail}
                                pword={pword}
                                setPword={setPword}
                            />


                            <div className="create__right__form__btnbox">

                                <button
                                    class="btn create__right__form__btn"
                                    onClick={() => handleLogin()}
                                >
                                    <span>Login</span>
                                    <AiOutlineArrowRight className='create__right__form__btn__icon' />
                                </button>

                            </div>

                            <div 
                                className="create__right__form__redirect"
                                onClick={() => navigate('/signup')}
                            >
                                Dont have an account? Sign up
                            </div>

                        </div>

                    </div>
                </div>

            </div>
            <ToastContainer />
            <Footer />
        </div>
    )
}


const Form = (props) => {

    return (
        <form action="#" className="create__right__form__element" id="create__form" onSubmit={(e) => props.setPageNo(1)}>



            <div class="create__right__form__group">

                <label
                    for="email"
                    className="create__right__form__label"
                >
                    Email
                </label>

                <div className="create__right__form__group__inputbox">

                    <div className="create__right__form__input__icon">
                        <MdEmail />
                    </div>

                    <input
                        type="email"
                        className="create__right__form__input"
                        placeholder="user@mailservice.com"
                        id="email"
                        required
                        value={props.email}
                        onChange={(e) => props.setEmail(e.target.value)}
                    />

                </div>

            </div>

            <div class="create__right__form__group">

                <label
                    for="pword"
                    className="create__right__form__label"

                >
                    Password
                </label>

                <div className="create__right__form__group__inputbox">

                    <div className="create__right__form__input__icon">
                        <RiLockPasswordFill />
                    </div>


                    <input
                        type="password"
                        className="create__right__form__input"
                        placeholder="Password"
                        id="pword"
                        required
                        value={props.pword}
                        onChange={(e) => props.setPword(e.target.value)}
                    />

                </div>

            </div>

        </form>
    )
}

export default Login
