import React, { useContext, useRef, useEffect, useState } from 'react'
import { AppDataContext } from '../../Contexts/AppDataContext';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import QRCode from 'react-qr-code';
import { ToastContainer, toast } from 'react-toastify';
import Sidebar from './Sidebar';
import Header from './Header';
import HeadImg from '../../img/licence.png'
import Logo from '../../img/Logo-nobg.png'
import NoDoc from '../../img/no-doc.png';
import { Footer, Spinner } from '../shared';
import jsPDF from 'jspdf';
import { reloadData } from './dbFunctions';
import _ from 'lodash'

function Licence() {

    const svgRef = useRef(null);
    const { userData, setUserData } = useContext(AppDataContext)
    const location = useLocation()
    const navigate = useNavigate()
    const [showSpinner, setShowSpinner] = useState(false)

    useEffect(() => {

        const handleRefresh = () => {
            const entries = performance.getEntriesByType('navigation');
            const navigationEntry = entries.length > 0 ? entries[0] : null;

            if (navigationEntry && navigationEntry.type === 'reload' && _.isEmpty(userData)) {
                console.log('Page refreshed');
                // Perform actions like re-fetching data, resetting state, etc.
                reloadData(location.state, setUserData, setShowSpinner, navigate)

            }
        };

        handleRefresh();

    }, [userData])

    const handleExport = () => {
        const svgElement = svgRef.current.querySelector('svg');
        if (!svgElement) return;

        // Create a canvas to draw the SVG as PNG
        const canvas = document.createElement('canvas');
        canvas.width = 256;
        canvas.height = 256;
        const ctx = canvas.getContext('2d');

        // Convert SVG to a Data URL and draw it on the canvas
        const svgData = new XMLSerializer().serializeToString(svgElement);
        const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
        const url = URL.createObjectURL(svgBlob);

        const image = new Image();
        image.onload = () => {
            ctx.drawImage(image, 0, 0);
            URL.revokeObjectURL(url);

            // Get the PNG data URL from the canvas
            const pngDataUrl = canvas.toDataURL('image/png');

            // Create the PDF and add the PNG image
            const pdf = new jsPDF();
            pdf.addImage(pngDataUrl, 'PNG', 15, 40, 150, 150); // Position the QR code in the PDF
            pdf.save('qrcode.pdf');
        };
        image.src = url;
    };

    return (
        <div className="home">
            <Sidebar data={location.state} />
            <Header data={location.state} />
            <div className="home__main">
                <div className="home__main__header">
                    <p>Your Licence Information Area</p>
                    <h3>Licence</h3>

                </div>

                {
                    showSpinner ?
                        <Spinner showSpinner={showSpinner} /> :


                        <div className="home__main__content">
                            <div className='licence'>
                                <div className="licence__container">

                                    <div className="licence__header">
                                        <div className="licence__header__text">
                                            <h4>Welcome to your Licence Area</h4>
                                            <p>Real Estate Agency Council Hub Ghana</p>
                                        </div>

                                        <div className="licence__header__img">
                                            <img src={HeadImg} alt="Page Icon" />
                                        </div>

                                    </div>

                                    <div className="licence__content">

                                        {
                                            userData.licenseID ?
                                                <div className="licence__content__qrcode">
                                                    <p>Provisional License</p>
                                                    <div className="licence__content__qrcode__wrap" ref={svgRef}>
                                                        <QRCode value={`https://reacghana.com/licence-doc/${userData.licenseID}`} size={256} id='qr-code' />
                                                    </div>

                                                    <div className="licence__content__qrcode__btns">
                                                        <button
                                                            className="btn licence__btn"
                                                            onClick={() => handleExport()}
                                                        >
                                                            Save QR Code
                                                        </button>

                                                        <button
                                                            className="btn licence__btn"
                                                            onClick={() => window.open(`https://reacghana.com/licence-doc/${userData.licenseID}`)}
                                                        >
                                                            View License
                                                        </button>
                                                    </div>

                                                </div>
                                                :
                                                <div className="licence__content__nodoc">
                                                    <img src={NoDoc} alt="No Licence" />
                                                    <p>Licence Not approved yet</p>
                                                </div>
                                        }


                                    </div>

                                </div>
                            </div>
                        </div>
                }
                <Footer />
            </div>
            <ToastContainer />
        </div>
    )
}

export default Licence