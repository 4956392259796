import React, { useContext } from "react";
import { AppDataContext } from "./Contexts/AppDataContext";
import { Navigate } from "react-router-dom";
import { getLoggedIn } from "./features/Home/dbFunctions";

const ProtectedRoute = ({ children }) => {
  //const { loggedIn } = useContext(AppDataContext);
  const loggedIn = getLoggedIn();
  //console.log(loggedIn)

  if (loggedIn === 'false') {
    return <Navigate to="/" replace />;
  }
  return children;
};

export default ProtectedRoute;
