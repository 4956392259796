import {
  collection,
  getDocs,
  query,
  where,
  getFirestore,
  updateDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import { setLoggedIn } from "../Home/dbFunctions";
import _ from "lodash";

async function loginUser(
  email,
  password,
  setUserData,
  
  setShowSpinner,
  navigate,
  notify
) {
  let result = [];

  try {
    const db = getFirestore();
    const usersRef = collection(db, "users");
    const q1 = query(
      usersRef,
      where("email", "==", email),
      where("password", "==", password)
    );
    const querySnapshot = await getDocs(q1);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      //console.log(doc.id, " => ", doc.data());
      result.push({ id: doc.id, ...doc.data() });
    });

    if (_.isEmpty(result)) {
      notify("Email or Password Wrong");
      setShowSpinner(false);
    } else {
      let userData = result[0];
      let companyData = {};

      if (userData.companyID !== "") {
        const docRef = doc(db, "companies", result[0].companyID);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          companyData = docSnap.data();
          // console.log("Document data:", docSnap.data());
        } else {
          // docSnap.data() will be undefined in this case
          // console.log("No such document!");
        }
      }

      //console.log(companyData)
      //console.log(userData)

      if (_.isEmpty(companyData)) {
        setUserData(userData);
      } else {
        setUserData({...userData, ...companyData});
      }

      setLoggedIn(true);
      setShowSpinner(false);
      navigate("/dashboard", { state: _.isEmpty(companyData) ? userData : {...userData, ...companyData} });
    }
  } catch (error) {
    setShowSpinner(false);
    notify("Error Logging in. Try again later");
    //console.log(error)
    throw error;
  }
}

export { loginUser };
