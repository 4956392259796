import React, { useContext } from 'react'
import { AppDataContext } from '../../Contexts/AppDataContext';
import Avatar from '../../img/real-estate-agent.png'
import { useLocation, useNavigate } from 'react-router-dom';
import { RiDashboardFill, RiLockPasswordFill } from "react-icons/ri";
import { TbLicense } from "react-icons/tb";
import { AiFillMessage } from "react-icons/ai";
import { SiGoogleforms } from "react-icons/si";
import { IoDocuments, IoLogOutSharp } from "react-icons/io5";
import Logo from '../../img/Logo-nobg.png'
import { setLoggedIn } from './dbFunctions';

const ROUTES = ['/dashboard', '/licence', '/messages', '/upload', '/resetpword', '/forms']

function Sidebar(props) {
    const { userData } = useContext(AppDataContext)

    const navigate = useNavigate()
    const location = useLocation();
    const route = location.pathname
    // console.log(route)


    return (
        <div className='sidebar'>
            <div className="sidebar__nav">
                <div className="sidebar__user">
                    <img src={props.data.passportLink} alt="Avatar of user" className='sidebar__user__icon' />
                    <div className="sidebar__user__details">
                        <h3>{props.data.firstName}</h3>
                        <p>{`Joined ${props.data.joined.split(' ')[3]}`}</p>
                    </div>
                </div>



                <div
                    className={`sidebar__item ${route === ROUTES[0] ? 'sidebar__item__selected' : ''}`}
                    onClick={() => navigate('/dashboard', {state: props.data})}
                >
                    <RiDashboardFill className='sidebar__item__icon' />
                    <p>Dashboard</p>
                </div>

                <div
                    className={`sidebar__item ${route === ROUTES[1] ? 'sidebar__item__selected' : ''}`}
                    onClick={() => navigate('/licence', {state: props.data})}
                >
                    <TbLicense className='sidebar__item__icon' />
                    <p>Licence</p>
                </div>

                <div
                    className={`sidebar__item ${route === ROUTES[2] ? 'sidebar__item__selected' : ''}`}
                    onClick={() => navigate('/messages', {state: props.data})}
                >
                    <AiFillMessage className='sidebar__item__icon' />
                    <p>Messages</p>
                </div>


                <div
                    className={`sidebar__item ${route === ROUTES[3] ? 'sidebar__item__selected' : ''}`}
                    onClick={() => navigate('/upload', {state: props.data})}
                >
                    <IoDocuments className='sidebar__item__icon' />
                    <p>Upload Docs</p>
                </div>

                <div
                    className={`sidebar__item ${route === ROUTES[5] ? 'sidebar__item__selected' : ''}`}
                    onClick={() => navigate('/forms', {state: props.data})}
                >
                    <SiGoogleforms className='sidebar__item__icon' />
                    <p>Forms</p>
                </div>


                <div
                    className={`sidebar__item ${route === ROUTES[4] ? 'sidebar__item__selected' : ''}`}
                    onClick={() => navigate('/resetpword', {state: props.data})}
                >
                    <RiLockPasswordFill className='sidebar__item__icon' />
                    <p>Reset Password</p>
                </div>



                <div
                    className={`sidebar__item`}
                    onClick={() => {
                        setLoggedIn('false')
                        navigate('/')
                    }}
                >
                    <IoLogOutSharp className='sidebar__item__icon' />
                    <p>Logout</p>
                </div>
            </div>

            <div className="sidebar__logo">
                <img src={Logo} alt="Logo of Company" />
            </div>


        </div>
    )
}

export default Sidebar